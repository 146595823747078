import styles from '../style.module.css';

import Spinner from './Spinner.js';

export default function Loading() {
    return (
        <section class={styles.section}>
            <Spinner />
        </section>
    );
}
