import { BitcoinSignTransaction } from '@icarus/features';
import { BitcoinChain } from '@icarus/types';
import { getBitcoinAddress } from './address.js';
/**
 * Get a Bitcoin wallet account for a public key.
 */
export async function getBitcoinWalletAccount(publicKey) {
    return {
        address: await getBitcoinAddress(publicKey),
        publicKey: publicKey.slice(),
        chains: [BitcoinChain.Mainnet],
        features: [BitcoinSignTransaction],
    };
}
