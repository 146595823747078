import { Network, } from '@icarus/types';
import { frozen } from '@icarus/utils';
import { getBitcoinKeyDerivation, getBitcoinWalletAccount } from './bitcoin/index.js';
import { getEthereumKeyDerivation, getEthereumWalletAccount } from './ethereum/index.js';
import { getSolanaKeyDerivation, getSolanaWalletAccount } from './solana/index.js';
export * from './bitcoin/index.js';
export * from './ethereum/index.js';
export * from './solana/index.js';
export function getKeyDerivation(network, levels) {
    return networkToGetKeyDerivation[network](levels);
}
export async function getWalletAccount(network, publicKey) {
    return await networkToGetWalletAccount[network](publicKey);
}
const networkToGetKeyDerivation = frozen({
    [Network.Bitcoin]: getBitcoinKeyDerivation,
    [Network.Ethereum]: getEthereumKeyDerivation,
    [Network.Solana]: getSolanaKeyDerivation,
});
const networkToGetWalletAccount = frozen({
    [Network.Bitcoin]: getBitcoinWalletAccount,
    [Network.Ethereum]: getEthereumWalletAccount,
    [Network.Solana]: getSolanaWalletAccount,
});
