import { AccountType, } from '@icarus/types';
export function getBaseAccountId(type) {
    return `account!type(${type})`;
}
export function getDerivedAccountId(derivedAccount) {
    return `network(${derivedAccount.network}):curve(${derivedAccount.curve}):path(${derivedAccount.path})`;
}
export function getPasskeyAccountId(passkeyId, derivedAccount) {
    return `${getBaseAccountId(AccountType.Passkey)}:${getDerivedAccountId(derivedAccount)}:${passkeyId}`;
}
export function getLedgerAccountId(ledgerId, derivedAccount) {
    return `${getBaseAccountId(AccountType.Ledger)}:${getDerivedAccountId(derivedAccount)}:${ledgerId}`;
}
