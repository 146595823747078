import { Buffer } from 'buffer';
import { StatusCodes, TransportStatusError } from '@ledgerhq/hw-transport';
import { Network } from '@icarus/types';
import { frozen, sleep } from '@icarus/utils';
import { connectTransport, disconnectTransport } from './transport.js';
export var DashboardName;
(function (DashboardName) {
    DashboardName["BOLOS"] = "BOLOS";
    DashboardName["OLOS"] = "OLOS\0";
})(DashboardName || (DashboardName = {}));
export var AppName;
(function (AppName) {
    AppName["Solana"] = "Solana";
    AppName["Ethereum"] = "Ethereum";
    AppName["Bitcoin"] = "Bitcoin";
})(AppName || (AppName = {}));
const networkToAppName = frozen({
    [Network.Solana]: AppName.Solana,
    [Network.Ethereum]: AppName.Ethereum,
    [Network.Bitcoin]: AppName.Bitcoin,
});
export function transformPath(path) {
    return path.replace(/^m\//, '');
}
export function getAppName(network) {
    return networkToAppName[network];
}
export async function getApp(transport, appName) {
    const { name } = await getAppInfo(transport);
    if (name !== appName) {
        if (!(name in DashboardName)) {
            await quitApp(transport);
        }
        await openApp(transport, appName);
    }
    if (appName === AppName.Solana) {
        const AppSolana = await import('./import/appSolana.js');
        // @ts-expect-error // HACK: Ledger's type definitions for the default export don't work.
        return new AppSolana(await connectTransport(transport));
    }
    else if (appName === AppName.Ethereum) {
        const AppEthereum = await import('./import/appEthereum.js');
        // @ts-expect-error // HACK: Ledger's type definitions for the default export don't work.
        return new AppEthereum(await connectTransport(transport));
    }
    else if (appName === AppName.Bitcoin) {
        const AppBitcoin = await import('./import/appBitcoin.js');
        // @ts-expect-error // HACK: Ledger's type definitions for the default export don't work.
        return new AppBitcoin({ transport: await connectTransport(transport) });
    }
    throw new Error(); // FIXME: Error message.
}
export async function getAppList(transport) {
    const transportInstance = await connectTransport(transport);
    const appList = [];
    let response = await transportInstance.send(0xe0, 0xde, 0, 0);
    // more than the status bytes
    while (response.length > 2) {
        let i = 0;
        const format = response[i++];
        if (format !== 1)
            throw new Error('unknown format'); // FIXME: Error messages.
        while (i < response.length - 2) {
            const length = response[i];
            i++;
            const blocks = response.readUInt16BE(i);
            i += 2;
            const flags = response.readUInt16BE(i);
            i += 2;
            const hashCodeData = response.slice(i, i + 32);
            i += 32;
            const hash = response.slice(i, i + 32);
            i += 32;
            const nameLength = response[i];
            i++;
            if (length !== nameLength + 70)
                throw new Error('invalid length'); // FIXME: Error messages.
            const name = response.slice(i, i + nameLength).toString('ascii');
            i += nameLength;
            appList.push({ name, blocks, flags, hashCodeData, hash });
        }
        response = await transportInstance.send(0xe0, 0xdf, 0, 0);
    }
    return appList;
}
export async function getAppInfo(transport) {
    const transportInstance = await connectTransport(transport);
    const response = await transportInstance.send(0xb0, 0x01, 0x00, 0x00);
    let i = 0;
    const format = response[i++];
    if (format !== 1)
        throw new Error('unknown format'); // FIXME: Error messages.
    const nameLength = response[i++];
    const name = response.slice(i, (i += nameLength)).toString('ascii');
    const versionLength = response[i++];
    const version = response.slice(i, (i += versionLength)).toString('ascii');
    const flagsLength = response[i++];
    const flags = response.slice(i, (i += flagsLength));
    return { name, version, flags };
}
export async function openApp(transport, appName) {
    const transportInstance = await connectTransport(transport);
    await transportInstance.send(0xe0, 0xd8, 0x00, 0x00, Buffer.from(appName, 'ascii'));
    // HACK: Transport disconnects after opening the app: https://github.com/LedgerHQ/ledger-live/issues/4964
    disconnectTransport(transport);
    await sleep(4000);
}
export async function quitApp(transport) {
    const transportInstance = await connectTransport(transport);
    await transportInstance.send(0xb0, 0xa7, 0x00, 0x00);
    // HACK: Transport disconnects after quitting the app: https://github.com/LedgerHQ/ledger-live/issues/4964
    disconnectTransport(transport);
    await sleep(4000);
}
export async function getDeviceName(transport) {
    const transportInstance = await connectTransport(transport);
    const response = await transportInstance.send(0xe0, 0xd2, 0x00, 0x00, Buffer.from([]), [
        StatusCodes.OK,
        StatusCodes.DEVICE_NOT_ONBOARDED,
        StatusCodes.DEVICE_NOT_ONBOARDED_2,
    ]);
    const status = response.readUInt16BE(response.length - 2);
    switch (status) {
        case StatusCodes.OK:
            return response.slice(0, response.length - 2).toString('utf-8');
        case StatusCodes.DEVICE_NOT_ONBOARDED:
        case StatusCodes.DEVICE_NOT_ONBOARDED_2:
            return '';
        default:
            // @ts-expect-error // HACK: TransportStatusError is a constructor function.
            throw new TransportStatusError(status);
    }
}
export async function setDeviceName(transport, deviceName) {
    const transportInstance = await connectTransport(transport);
    // FIXME: Handle max length (17 or 20) by device model.
    await transportInstance.send(0xe0, 0xd4, 0x00, 0x00, Buffer.from(deviceName, 'utf-8'));
}
