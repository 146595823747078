import { useMemo } from 'preact/hooks';

import { useWallet } from '../hooks/useWallet.js';
import styles from '../style.module.css';

export default function Overlay() {
    const { syncPasskey, setState } = useWallet();

    const confirm = useMemo(() => () => setState({ syncPasskey: true }), [setState]);
    const cancel = useMemo(() => () => setState({ syncPasskey: false }), [setState]);

    return syncPasskey ? (
        <section class={styles.overlay}>
            <div class={styles.confirm}>
                <h2 class={styles.subtitle}>Do you want to sync your account to this device?</h2>
                <button class={styles.button} onClick={confirm}>
                    Yes
                </button>
                <button class={styles.text} onClick={cancel}>
                    I'll do this later.
                </button>
            </div>
        </section>
    ) : null;
}
