import { createContext } from 'preact';

import type { BitcoinConfig, EthereumConfig, SolanaConfig, connect } from '@icarus/ledger';
import type {
    DerivedAccount,
    LedgerAccount,
    LedgerAccounts,
    LedgerDevices,
    LedgerTransport,
    Network,
    SignatureBytes,
} from '@icarus/types';
import type { Frozen } from '@icarus/utils';

export interface LedgerContextValue {
    devices: LedgerDevices;
    accounts: LedgerAccounts;
    transport: LedgerTransport;
    setTransport(newTransport: LedgerTransport): void;
    connect(): ReturnType<typeof connect>;
    getConfig<N extends Network>(
        network: N
    ): Promise<
        N extends Network.Solana
            ? SolanaConfig
            : N extends Network.Ethereum
            ? EthereumConfig
            : N extends Network.Bitcoin
            ? BitcoinConfig
            : never
    >;
    // FIXME: Add device arg / association.
    deriveAccount(derivedAccount: DerivedAccount): Promise<LedgerAccount>;
    signTransaction(
        account: LedgerAccount,
        transaction: Uint8Array
    ): Promise<{ signedTransaction: Uint8Array; signature: SignatureBytes }>;
    signMessage(
        account: LedgerAccount,
        message: Uint8Array
    ): Promise<{ signedMessage: Uint8Array; signature: SignatureBytes }>;
}

export const LedgerContext = createContext<Frozen<LedgerContextValue> | undefined>(undefined);
