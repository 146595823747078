/**
 * Efficiently concatenate byte arrays without modifying them.
 *
 * @param first  A byte array.
 * @param others Additional byte arrays.
 *
 * @return New byte array containing the concatenation of all the byte arrays.
 *
 * @group Util
 */
export function concatBytes(first, ...others) {
    const length = others.reduce((length, bytes) => length + bytes.length, first.length);
    const bytes = new Uint8Array(length);
    bytes.set(first, 0);
    for (const other of others) {
        bytes.set(other, bytes.length);
    }
    return bytes;
}
/**
 * Get a byte array of cryptographically secure random bytes.
 *
 * @param number Number of bytes.
 *
 * @return New byte array containing `number` random bytes.
 *
 * @group Util
 */
export function getRandomBytes(number) {
    const bytes = new Uint8Array(number);
    crypto.getRandomValues(bytes);
    return bytes;
}
/**
 * Efficiently compare byte arrays.
 *
 * @param a A byte array.
 * @param b Another byte array.
 *
 * @return `true` if the byte arrays have the same length and bytes, `false` otherwise.
 *
 * @group Util
 */
/* global BufferSource */
export function bytesEqual(a, b) {
    if (a.byteLength != b.byteLength)
        return false;
    const ua = a instanceof ArrayBuffer ? new Uint8Array(a, 0) : a;
    const ub = b instanceof ArrayBuffer ? new Uint8Array(b, 0) : b;
    if (bytesAligned32(ua) && bytesAligned32(ub))
        return bytesEqual32(ua, ub);
    if (bytesAligned16(ua) && bytesAligned16(ub))
        return bytesEqual16(ua, ub);
    return bytesEqual8(ua, ub);
}
function bytesEqual8(a, b) {
    const ua = new Uint8Array(a.buffer, a.byteOffset, a.byteLength);
    const ub = new Uint8Array(b.buffer, b.byteOffset, b.byteLength);
    return compareBytes(ua, ub);
}
function bytesEqual16(a, b) {
    const ua = new Uint16Array(a.buffer, a.byteOffset, a.byteLength / 2);
    const ub = new Uint16Array(b.buffer, b.byteOffset, b.byteLength / 2);
    return compareBytes(ua, ub);
}
function bytesEqual32(a, b) {
    const ua = new Uint32Array(a.buffer, a.byteOffset, a.byteLength / 4);
    const ub = new Uint32Array(b.buffer, b.byteOffset, b.byteLength / 4);
    return compareBytes(ua, ub);
}
function bytesAligned16(a) {
    return a.byteOffset % 2 === 0 && a.byteLength % 2 === 0;
}
function bytesAligned32(a) {
    return a.byteOffset % 4 === 0 && a.byteLength % 4 === 0;
}
function compareBytes(a, b) {
    for (let i = a.length; -1 < i; --i) {
        if (a[i] !== b[i])
            return false;
    }
    return true;
}
