import { LedgerTransport } from '@icarus/types';
import { frozen } from '@icarus/utils';
const getTransportClass = frozen({
    async [LedgerTransport.BLE]() {
        // @ts-expect-error // HACK: The default export is correct.
        return await import('./import/transportBLE.js');
    },
    async [LedgerTransport.HID]() {
        // @ts-expect-error // HACK: The default export is correct.
        return await import('./import/transportHID.js');
    },
    async [LedgerTransport.USB]() {
        // @ts-expect-error // HACK: The default export is correct.
        return await import('./import/transportUSB.js');
    },
});
const transportInstances = Object.seal({
    [LedgerTransport.BLE]: null,
    [LedgerTransport.HID]: null,
    [LedgerTransport.USB]: null,
});
async function createTransport(transport) {
    const TransportClass = await getTransportClass[transport]();
    const transportInstance = 'request' in TransportClass &&
        typeof TransportClass.request === 'function' &&
        (await TransportClass.list()).length > 1
        ? await TransportClass.request()
        : await TransportClass.create();
    const onDisconnect = () => {
        transportInstance.off('disconnect', onDisconnect);
        transportInstance.close();
        transportInstances[transport] = null;
    };
    transportInstance.on('disconnect', onDisconnect);
    // @ts-expect-error // HACK: transportInstance is a TransportInstance.
    return transportInstance;
}
export async function connectTransport(transport) {
    return (transportInstances[transport] ??= await createTransport(transport));
}
export function disconnectTransport(transport) {
    const transportInstance = transportInstances[transport];
    if (transportInstance) {
        transportInstance.emit('disconnect');
    }
}
