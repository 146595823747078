import { Curve } from '@icarus/types';
import { getKeyDerivationPath } from '@icarus/utils';
const purpose = 44;
const coin = 501;
/**
 * Get the configuration for deriving a Solana key.
 */
export function getSolanaKeyDerivation(levels) {
    return {
        curve: Curve.Ed25519,
        path: getKeyDerivationPath({ purpose, coin, hardened: true, ...levels }),
    };
}
