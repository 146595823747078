import { hexToBytes } from '@icarus/utils';
import { AppName, getApp, transformPath } from '../ledger.js';
export async function bitcoinGetAccount(transport, path) {
    const app = await getApp(transport, AppName.Bitcoin);
    const { publicKey: publicKeyHex } = await app.getWalletPublicKey(transformPath(path), {
        verify: false,
        format: 'legacy',
    });
    return hexToBytes(publicKeyHex);
}
