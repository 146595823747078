import { bytesToHex, hexToBytes } from '@icarus/utils';
import { AppName, getApp, transformPath } from '../ledger.js';
export const EthereumSignTransaction = 'ethereum:signTransaction';
export async function ethereumSignTransaction(transport, path, transaction) {
    const app = await getApp(transport, AppName.Ethereum);
    // FIXME: replace with `clearSignTransaction`?
    const { r, s, v } = await app.signTransaction(transformPath(path), bytesToHex(transaction), null);
    return new Uint8Array([
        ...hexToBytes(r),
        ...hexToBytes(s),
        ...hexToBytes(v),
    ]);
}
