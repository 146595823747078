import { isPrintableAsciiBytes, isUtf8Bytes } from '@icarus/utils';

export enum SolanaOffchainMessageFormat {
    RestrictedAscii,
    LimitedUtf8,
    ExtendedUtf8,
}

export enum SolanaOffchainMessageLength {
    Max = 65515,
    Ledger = 1212,
}

export function formatSolanaOffchainMessage(message: Uint8Array): {
    formatted: Uint8Array;
    format: SolanaOffchainMessageFormat;
} {
    let format: SolanaOffchainMessageFormat;

    if (!message.length) throw new Error('InvalidValue'); // FIXME: Error messages.
    if (message.length <= SolanaOffchainMessageLength.Ledger) {
        if (isPrintableAsciiBytes(message)) {
            format = SolanaOffchainMessageFormat.RestrictedAscii;
        } else if (isUtf8Bytes(message)) {
            format = SolanaOffchainMessageFormat.LimitedUtf8;
        } else {
            throw new Error('InvalidValue'); // FIXME: Error messages.
        }
    } else if (message.length <= SolanaOffchainMessageLength.Max) {
        if (isUtf8Bytes(message)) {
            format = SolanaOffchainMessageFormat.ExtendedUtf8;
        } else {
            throw new Error('InvalidValue'); // FIXME: Error messages.
        }
    } else {
        throw new Error('ValueOutOfBounds'); // FIXME: Error messages.
    }

    // Implementation @ https://github.com/solana-labs/solana/blob/5d112270882017cef86f5120d04c9a6cec273ce6/sdk/src/offchain_message.rs
    // Example @ https://github.com/LedgerHQ/ledger-live/blob/d4702fb431e06710ae30ec879e52c503861e5b01/libs/ledgerjs/packages/hw-app-solana/tests/Solana.test.ts#L78-L81
    // ff  s  o  l  a  n  a     o  f  f  c  h  a  i  n  0  0 28  0                             Long Off-Chain Test Message.
    // ff 73 6f 6c 61 6e 61 20 6f 66 66 63 68 61 69 6e 00 00 1c 00 4c6f6e67204f66662d436861696e2054657374204d6573736167652e

    // prettier-ignore
    const formatted = new Uint8Array([
        // \xff
        255,
        // utf8ToBytes('solana offchain')
        115, 111, 108, 97, 110, 97, 32, 111, 102, 102, 99, 104, 97, 105, 110,
        // version
        0,
        // format
        format,
        // length (2 bytes, little endian)
        message.length & 0x00FF,
        (message.length & 0xFF00) >> 8,
        // message
        ...message
    ]);

    return { formatted, format };
}
