import type { DetailedHTMLProps, HTMLAttributes } from 'preact/compat';

import styles from '../style.module.css';

export default function Spinner({
    width = 96,
    height = 96,
    ...props
}: DetailedHTMLProps<HTMLAttributes<HTMLImageElement>, HTMLImageElement>) {
    return (
        <img src="/img/spinner.svg" width={width} height={height} alt="Loading ..." class={styles.spinner} {...props} />
    );
}
