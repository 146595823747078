import { lazy } from 'preact/compat';
import { Switch } from 'wouter-preact';

import AsyncRoute from '../components/AsyncRoute.js';
import Overlay from '../components/Overlay.js';
import Toaster from '../components/Toaster.js';
import LedgerProvider from '../providers/LedgerProvider.js';
import WalletProvider from '../providers/WalletProvider.js';
import styles from '../style.module.css';

const IcarusHome = lazy(() => import('./icarus/home/home.js'));
const IcarusAccount = lazy(() => import('./icarus/account/account.js'));
const IcarusSignUp = lazy(() => import('./icarus/signUp/signUp.js'));
const IcarusLogIn = lazy(() => import('./icarus/logIn/logIn.js'));
const StandardConnect = lazy(() => import('./standard/connect/connect.js'));
const SolanaPay = lazy(() => import('./solana/pay/pay.js'));
const SolanaSignAndSendTransaction = lazy(() => import('./solana/signAndSendTransaction/signAndSendTransaction.js'));
const SolanaSignIn = lazy(() => import('./solana/signIn/signIn.js'));
const SolanaSignMessage = lazy(() => import('./solana/signMessage/signMessage.js'));
const SolanaSignTransaction = lazy(() => import('./solana/signTransaction/signTransaction.js'));
const LedgerConnect = lazy(() => import('./ledger/connect/connect.js'));
const ErrorNotFound = lazy(() => import('./error/notFound.js'));

export default function Routes() {
    return (
        <WalletProvider>
            <LedgerProvider>
                <main class={styles.main}>
                    <Switch>
                        <AsyncRoute path="/">
                            <IcarusHome />
                        </AsyncRoute>
                        <AsyncRoute path="/signUp">
                            <IcarusSignUp />
                        </AsyncRoute>
                        <AsyncRoute path="/logIn">
                            <IcarusLogIn />
                        </AsyncRoute>
                        <AsyncRoute path="/account">
                            <IcarusAccount />
                        </AsyncRoute>
                        <AsyncRoute path="/standard/connect">
                            <StandardConnect />
                        </AsyncRoute>
                        <AsyncRoute path="/solana/pay">
                            <SolanaPay />
                        </AsyncRoute>
                        <AsyncRoute path="/solana/signAndSendTransaction">
                            <SolanaSignAndSendTransaction />
                        </AsyncRoute>
                        <AsyncRoute path="/solana/signIn">
                            <SolanaSignIn />
                        </AsyncRoute>
                        <AsyncRoute path="/solana/signMessage">
                            <SolanaSignMessage />
                        </AsyncRoute>
                        <AsyncRoute path="/solana/signTransaction">
                            <SolanaSignTransaction />
                        </AsyncRoute>
                        <AsyncRoute path="/ledger/connect">
                            <LedgerConnect />
                        </AsyncRoute>
                        <AsyncRoute path="/:path*">
                            <ErrorNotFound />
                        </AsyncRoute>
                    </Switch>
                    <Overlay />
                    <Toaster />
                </main>
            </LedgerProvider>
        </WalletProvider>
    );
}
