import type { ComponentChildren } from 'preact';
import { useCallback, useMemo, useState } from 'preact/hooks';

import { getKeyDerivation, getWalletAccount } from '@icarus/accounts';
import {
    bitcoinGetAccount,
    bitcoinGetConfig,
    bitcoinSignMessage,
    bitcoinSignTransaction,
    ethereumGetAccount,
    ethereumGetConfig,
    ethereumSignMessage,
    ethereumSignTransaction,
    connect as ledgerConnect,
    solanaGetAccount,
    solanaGetConfig,
    solanaSignMessage,
    solanaSignTransaction,
} from '@icarus/ledger';
import {
    AccountType,
    LedgerTransport,
    Network,
    type KeyDerivationPath,
    type LedgerAccount,
    type LedgerId,
    type PublicKeyBytes,
} from '@icarus/types';
import { frozen, getLedgerAccountId, getLedgerId } from '@icarus/utils';

import { LedgerContext, type LedgerContextValue } from '../contexts/ledger.js';
import { SolanaOffchainMessageFormat, formatSolanaOffchainMessage } from '../utils/ledger.js';

export interface LedgerProviderProps {
    children: NonNullable<ComponentChildren>;
}

export default function LedgerProvider({ children }: LedgerProviderProps) {
    // FIXME: Use this.
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const [devices, setDevices] = useState<LedgerContextValue['devices']>({});

    const [accounts, setAccounts] = useState<LedgerContextValue['accounts']>({});

    // FIXME: Implement intelligent transport selection with user override.
    const [transport, setTransport] = useState(LedgerTransport.HID);

    const connect: LedgerContextValue['connect'] = useCallback(() => ledgerConnect(transport), [transport]);

    const getConfig: LedgerContextValue['getConfig'] = useCallback(
        async (network) => {
            if (network === Network.Solana) {
                return await solanaGetConfig(transport);
            } else if (network === Network.Ethereum) {
                return await ethereumGetConfig(transport);
            } else if (network === Network.Bitcoin) {
                return await bitcoinGetConfig(transport);
            }
            throw new Error(); // FIXME: Add error messages.
            // HACK: Unreachable return to silence conditional type error.
            // eslint-disable-next-line no-unreachable,@typescript-eslint/no-explicit-any
            return undefined as any;
        },
        [transport]
    );

    const getPublicKey = useCallback(
        async (network: Network, path: KeyDerivationPath): Promise<PublicKeyBytes> => {
            if (network === Network.Solana) {
                return await solanaGetAccount(transport, path);
            } else if (network === Network.Ethereum) {
                return await ethereumGetAccount(transport, path);
            } else if (network === Network.Bitcoin) {
                return await bitcoinGetAccount(transport, path);
            }
            throw new Error(); // FIXME: Add error messages.
        },
        [transport]
    );

    const deriveAccount: LedgerContextValue['deriveAccount'] = useCallback(
        async ({ network, curve, path }) => {
            // Get the public key of the target account at the derivation path.
            const publicKey = await getPublicKey(network, path);

            // Identify the Ledger device by the public key of the account at the root derivation path to associate accounts on the same device.
            let ledgerId: LedgerId;
            try {
                const { path } = getKeyDerivation(network, {});
                const publicKey = await getPublicKey(network, path);
                ledgerId = getLedgerId(network, path, publicKey);
            } catch (error) {
                // If this fails, identify the Ledger device by the public key of the target account and don't associate the account.
                ledgerId = getLedgerId(network, path, publicKey);
            }

            const walletAccount = await getWalletAccount(network, publicKey);

            const newAccount: LedgerAccount = {
                type: AccountType.Ledger,
                accountId: getLedgerAccountId(ledgerId, { network, curve, path }),
                ledgerId,
                network,
                curve,
                path,
                ...walletAccount,
            };

            // FIXME: This will overwrite account labels, icons, etc.
            setAccounts((currentAccounts) => ({ ...currentAccounts, [newAccount.accountId]: newAccount }));

            return newAccount;
        },
        [getPublicKey, setAccounts]
    );

    const signTransaction: LedgerContextValue['signTransaction'] = useCallback(
        async (account, transaction) => {
            if (account.network === Network.Solana) {
                const signature = await solanaSignTransaction(transport, account.path, transaction);
                return { signedTransaction: transaction, signature };
            } else if (account.network === Network.Ethereum) {
                const signature = await ethereumSignTransaction(transport, account.path, transaction);
                return { signedTransaction: transaction, signature };
            } else if (account.network === Network.Bitcoin) {
                const signature = await bitcoinSignTransaction(transport, account.path, transaction);
                return { signedTransaction: transaction, signature };
            }
            throw new Error(); // FIXME: Add error messages.
        },
        [transport]
    );

    const signMessage: LedgerContextValue['signMessage'] = useCallback(
        async (account, message) => {
            if (account.network === Network.Solana) {
                const { formatted, format } = formatSolanaOffchainMessage(message);
                switch (format) {
                    case SolanaOffchainMessageFormat.RestrictedAscii:
                        break;
                    case SolanaOffchainMessageFormat.LimitedUtf8: {
                        const { blindSigningEnabled } = await solanaGetConfig(transport);
                        if (!blindSigningEnabled) throw new Error(); // FIXME: Add error messages.
                        break;
                    }
                    case SolanaOffchainMessageFormat.ExtendedUtf8:
                        throw new Error(); // FIXME: Add error messages.
                    default:
                        throw new Error(); // FIXME: Add error messages.
                }

                const signature = await solanaSignMessage(transport, account.path, formatted);
                return { signedMessage: formatted, signature };
            } else if (account.network === Network.Ethereum) {
                // FIXME: Add formatting.
                const signature = await ethereumSignMessage(transport, account.path, message);
                return { signedMessage: message, signature };
            } else if (account.network === Network.Bitcoin) {
                const signature = await bitcoinSignMessage(transport, account.path, message);
                return { signedMessage: message, signature };
            }
            throw new Error(); // FIXME: Add error messages.
        },
        [transport]
    );

    // Memoize the context value for performance, and freeze it so it can't be modified by components.
    const value = useMemo(
        () =>
            frozen({
                devices,
                accounts,
                transport,
                setTransport,
                connect,
                getConfig,
                deriveAccount,
                signTransaction,
                signMessage,
            }),
        [devices, accounts, transport, setTransport, connect, getConfig, deriveAccount, signTransaction, signMessage]
    );

    return <LedgerContext.Provider value={value}>{children}</LedgerContext.Provider>;
}
