import { useContext } from 'preact/hooks';

import type { Frozen } from '@icarus/utils';

import { WalletContext, type WalletContextValue } from '../contexts/wallet.js';

export function useWallet(): Frozen<WalletContextValue> {
    const context = useContext(WalletContext);
    if (!context) throw new Error('useWallet must be used within a WalletProvider');
    return context;
}
