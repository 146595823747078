export var LedgerTransport;
(function (LedgerTransport) {
    // https://developer.mozilla.org/en-US/docs/Web/API/Web_Bluetooth_API
    LedgerTransport["BLE"] = "ble";
    // https://developer.mozilla.org/en-US/docs/Web/API/WebHID_API
    LedgerTransport["HID"] = "hid";
    // https://developer.mozilla.org/en-US/docs/Web/API/WebUSB_API
    LedgerTransport["USB"] = "usb";
})(LedgerTransport || (LedgerTransport = {}));
export var LedgerStatusCodes;
(function (LedgerStatusCodes) {
    LedgerStatusCodes[LedgerStatusCodes["AccessConditionNotFulfilled"] = 38916] = "AccessConditionNotFulfilled";
    LedgerStatusCodes[LedgerStatusCodes["AlgorithmNotSupported"] = 38020] = "AlgorithmNotSupported";
    LedgerStatusCodes[LedgerStatusCodes["ClaNotSupported"] = 28160] = "ClaNotSupported";
    LedgerStatusCodes[LedgerStatusCodes["CodeBlocked"] = 38976] = "CodeBlocked";
    LedgerStatusCodes[LedgerStatusCodes["CodeNotInitialized"] = 38914] = "CodeNotInitialized";
    LedgerStatusCodes[LedgerStatusCodes["CommandIncompatibleFileStructure"] = 27009] = "CommandIncompatibleFileStructure";
    LedgerStatusCodes[LedgerStatusCodes["ConditionsOfUseNotSatisfied"] = 27013] = "ConditionsOfUseNotSatisfied";
    LedgerStatusCodes[LedgerStatusCodes["ContradictionInvalidation"] = 38928] = "ContradictionInvalidation";
    LedgerStatusCodes[LedgerStatusCodes["ContradictionSecretCodeStatus"] = 38920] = "ContradictionSecretCodeStatus";
    LedgerStatusCodes[LedgerStatusCodes["CustomImageBootloader"] = 26159] = "CustomImageBootloader";
    LedgerStatusCodes[LedgerStatusCodes["CustomImageEmpty"] = 26158] = "CustomImageEmpty";
    LedgerStatusCodes[LedgerStatusCodes["FileAlreadyExists"] = 27273] = "FileAlreadyExists";
    LedgerStatusCodes[LedgerStatusCodes["FileNotFound"] = 37892] = "FileNotFound";
    LedgerStatusCodes[LedgerStatusCodes["GpAuthFailed"] = 25344] = "GpAuthFailed";
    LedgerStatusCodes[LedgerStatusCodes["Halted"] = 28586] = "Halted";
    LedgerStatusCodes[LedgerStatusCodes["InconsistentFile"] = 37896] = "InconsistentFile";
    LedgerStatusCodes[LedgerStatusCodes["IncorrectData"] = 27264] = "IncorrectData";
    LedgerStatusCodes[LedgerStatusCodes["IncorrectLength"] = 26368] = "IncorrectLength";
    LedgerStatusCodes[LedgerStatusCodes["IncorrectP1P2"] = 27392] = "IncorrectP1P2";
    LedgerStatusCodes[LedgerStatusCodes["InsNotSupported"] = 27904] = "InsNotSupported";
    LedgerStatusCodes[LedgerStatusCodes["DeviceNotOnboarded"] = 27911] = "DeviceNotOnboarded";
    LedgerStatusCodes[LedgerStatusCodes["DeviceNotOnboarded2"] = 26129] = "DeviceNotOnboarded2";
    LedgerStatusCodes[LedgerStatusCodes["InvalidKcv"] = 38021] = "InvalidKcv";
    LedgerStatusCodes[LedgerStatusCodes["InvalidOffset"] = 37890] = "InvalidOffset";
    LedgerStatusCodes[LedgerStatusCodes["Licensing"] = 28482] = "Licensing";
    LedgerStatusCodes[LedgerStatusCodes["LockedDevice"] = 21781] = "LockedDevice";
    LedgerStatusCodes[LedgerStatusCodes["MaxValueReached"] = 38992] = "MaxValueReached";
    LedgerStatusCodes[LedgerStatusCodes["MemoryProblem"] = 37440] = "MemoryProblem";
    LedgerStatusCodes[LedgerStatusCodes["MissingCriticalParameter"] = 26624] = "MissingCriticalParameter";
    LedgerStatusCodes[LedgerStatusCodes["NoEfSelected"] = 37888] = "NoEfSelected";
    LedgerStatusCodes[LedgerStatusCodes["NotEnoughMemorySpace"] = 27268] = "NotEnoughMemorySpace";
    LedgerStatusCodes[LedgerStatusCodes["Ok"] = 36864] = "Ok";
    LedgerStatusCodes[LedgerStatusCodes["PinRemainingAttempts"] = 25536] = "PinRemainingAttempts";
    LedgerStatusCodes[LedgerStatusCodes["ReferencedDataNotFound"] = 27272] = "ReferencedDataNotFound";
    LedgerStatusCodes[LedgerStatusCodes["SecurityStatusNotSatisfied"] = 27010] = "SecurityStatusNotSatisfied";
    LedgerStatusCodes[LedgerStatusCodes["TechnicalProblem"] = 28416] = "TechnicalProblem";
    LedgerStatusCodes[LedgerStatusCodes["UnknownApdu"] = 27906] = "UnknownApdu";
    LedgerStatusCodes[LedgerStatusCodes["UserRefusedOnDevice"] = 21761] = "UserRefusedOnDevice";
    LedgerStatusCodes[LedgerStatusCodes["NotEnoughSpace"] = 20738] = "NotEnoughSpace";
})(LedgerStatusCodes || (LedgerStatusCodes = {}));
