import { Curve } from '@icarus/types';
import { getKeyDerivationPath } from '@icarus/utils';
const purpose = 44;
const coin = 0;
/**
 * Get the configuration for deriving a Bitcoin key.
 */
export function getBitcoinKeyDerivation(levels) {
    return {
        curve: Curve.Secp256k1,
        path: getKeyDerivationPath({ purpose, coin, hardened: false, ...levels }),
    };
}
