import { SolanaSignAndSendTransaction, SolanaSignIn, SolanaSignMessage, SolanaSignTransaction } from '@icarus/features';
import { SolanaChain } from '@icarus/types';
import { getSolanaAddress } from './address.js';
/**
 * Get a Solana wallet account for a public key.
 */
export async function getSolanaWalletAccount(publicKey) {
    return {
        address: await getSolanaAddress(publicKey),
        publicKey: publicKey.slice(),
        chains: [SolanaChain.Mainnet, SolanaChain.Devnet, SolanaChain.Testnet, SolanaChain.Localnet],
        features: [SolanaSignAndSendTransaction, SolanaSignTransaction, SolanaSignMessage, SolanaSignIn],
    };
}
