export function getKeyDerivationPath({ purpose, coin, hardened, account, change, address, }) {
    let path = `m/${purpose}'/${coin}'`;
    if (account != null) {
        path = `${path}/${account}'`;
    }
    if (change != null) {
        path = `${path}/${change}`;
        if (hardened) {
            path = `${path}'`;
        }
    }
    if (address != null) {
        path = `${path}/${address}`;
        if (hardened) {
            path = `${path}'`;
        }
    }
    return path;
}
