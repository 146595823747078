import { AppName, getApp, getAppInfo } from '../ledger.js';
export async function bitcoinGetConfig(transport) {
    // The Bitcoin app doesn't have a `getConfig` method, so just get the version by opening it.
    await getApp(transport, AppName.Bitcoin);
    const { name, version } = await getAppInfo(transport);
    // If it's the Bitcoin app, return the version.
    if (name === AppName.Bitcoin)
        return { version };
    // Otherwise, the user has closed the app, so try again.
    return await bitcoinGetConfig(transport);
}
