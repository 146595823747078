import { EthereumSignTransaction } from '@icarus/features';
import { EthereumChain } from '@icarus/types';
import { getEthereumAddress } from './address.js';
/**
 * Get the Ethereum wallet account for a public key.
 */
export async function getEthereumWalletAccount(publicKey) {
    return {
        address: await getEthereumAddress(publicKey),
        publicKey: publicKey.slice(),
        chains: [EthereumChain.Mainnet],
        features: [EthereumSignTransaction],
    };
}
