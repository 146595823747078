import { base58, base64, base64urlnopad, hex, utf8 } from '@scure/base';
export function bytesToBase58(bytes) {
    return base58.encode(bytes);
}
export function base58ToBytes(base58String) {
    return base58.decode(base58String);
}
export function bytesToBase64(bytes) {
    return base64.encode(bytes);
}
export function base64ToBytes(base64String) {
    return base64.decode(base64String);
}
export function bytesToBase64Url(bytes) {
    return base64urlnopad.encode(bytes);
}
export function base64UrlToBytes(base64UrlString) {
    return base64urlnopad.decode(base64UrlString);
}
export function bytesToHex(bytes) {
    return hex.encode(bytes);
}
export function hexToBytes(hexString) {
    return hex.decode(hexString);
}
export function bytesToUtf8(bytes) {
    return utf8.encode(bytes);
}
export function utf8ToBytes(utf8String) {
    return utf8.decode(utf8String);
}
export function isPrintableAsciiBytes(bytes) {
    for (const byte of bytes) {
        if (byte < 0x20 || byte > 0x7e)
            return false;
    }
    return true;
}
export function isUtf8Bytes(bytes) {
    try {
        new TextDecoder('utf-8', { fatal: true }).decode(bytes);
    }
    catch (error) {
        return false;
    }
    return true;
}
