export var BitcoinChain;
(function (BitcoinChain) {
    BitcoinChain["Mainnet"] = "bitcoin:mainnet";
})(BitcoinChain || (BitcoinChain = {}));
export var EthereumChain;
(function (EthereumChain) {
    EthereumChain["Mainnet"] = "ethereum:mainnet";
})(EthereumChain || (EthereumChain = {}));
export var SolanaChain;
(function (SolanaChain) {
    SolanaChain["Mainnet"] = "solana:mainnet";
    SolanaChain["Devnet"] = "solana:devnet";
    SolanaChain["Testnet"] = "solana:testnet";
    SolanaChain["Localnet"] = "solana:localnet";
})(SolanaChain || (SolanaChain = {}));
