import type { ComponentChildren } from 'preact';
import { useCallback, useEffect, useMemo, useState } from 'preact/hooks';

import {
    IcarusCreatePasskey,
    IcarusDeriveAccounts,
    IcarusError,
    IcarusGetPasskey,
    IcarusInvalid,
    IcarusReady,
    IcarusSign,
    IcarusState,
    IcarusUnknown,
} from '@icarus/ui-messages';
import { frozen, getMessageId } from '@icarus/utils';
import { IcarusCancel, IcarusClose } from '@icarus/wallet-messages';

import { WalletContext, type ErrorMessage, type InputMessage, type WalletContextValue } from '../contexts/wallet.js';

export interface WalletProviderProps {
    children: NonNullable<ComponentChildren>;
}

export default function WalletProvider({ children }: WalletProviderProps) {
    const { port1: port, port2: transferPort } = useMemo(() => new MessageChannel(), []);

    const [{ origin, input, output, passkeys, accounts, syncPasskey, applications, application, errors }, _setState] =
        useState<{
            origin: WalletContextValue['origin'];
            input: WalletContextValue['input'];
            output: WalletContextValue['output'];
            passkeys: WalletContextValue['passkeys'];
            accounts: WalletContextValue['accounts'];
            applications: WalletContextValue['applications'];
            application: WalletContextValue['application'];
            errors: WalletContextValue['errors'];
            syncPasskey: WalletContextValue['syncPasskey'];
        }>(() => ({
            origin: null,
            input: null,
            output: null,
            passkeys: {},
            accounts: {},
            syncPasskey: false,
            applications: {},
            application: null,
            errors: [],
        }));

    useEffect(() => {
        const onMessage = (event: MessageEvent<InputMessage>) => {
            const input = event.data;
            if (!input || typeof input.id !== 'string' || typeof input.type !== 'string') return;
            if (input.type !== IcarusState) return;
            _setState(input.input);
        };

        port.addEventListener('message', onMessage);
        port.start();

        return () => {
            port.removeEventListener('message', onMessage);
            port.close();
        };
    }, [port]);

    useEffect(() => {
        window.parent.postMessage({ type: IcarusReady }, process.env.WALLET_URL!, [transferPort]);
    }, [transferPort]);

    const send: WalletContextValue['send'] = useCallback(
        (output, transfer = []) => {
            return new Promise((resolve, reject: (error: ErrorMessage) => void) => {
                type I = Parameters<typeof resolve>[0];

                output.id ??= getMessageId();

                if (
                    output.type === IcarusCreatePasskey ||
                    output.type === IcarusGetPasskey ||
                    output.type === IcarusDeriveAccounts ||
                    output.type === IcarusSign
                ) {
                    const onMessage = (event: MessageEvent<InputMessage | ErrorMessage>) => {
                        const input = event.data;
                        try {
                            if (!input || typeof input.id !== 'string' || typeof input.type !== 'string') {
                                reject({ id: input.id, type: IcarusInvalid });
                            } else if (input.id !== output.id) {
                                // Do nothing and return without resetting.
                                return;
                            } else if (
                                input.type === IcarusCreatePasskey ||
                                input.type === IcarusGetPasskey ||
                                input.type === IcarusDeriveAccounts ||
                                input.type === IcarusSign
                            ) {
                                if (input.type === output.type) {
                                    // HACK: input is I but type is wrong.
                                    resolve(input as I);
                                } else {
                                    reject({ id: input.id, type: IcarusInvalid });
                                }
                            } else if (
                                input.type === IcarusInvalid ||
                                input.type === IcarusUnknown ||
                                input.type === IcarusError
                            ) {
                                reject(input);
                            } else {
                                reject({ id: input.id, type: IcarusUnknown });
                            }
                        } catch (error) {
                            reject({ id: input?.id, type: IcarusError, error: String(error) });
                        }

                        port.removeEventListener('message', onMessage);
                    };

                    port.addEventListener('message', onMessage);
                } else {
                    resolve(undefined as I);
                }

                port.postMessage(output, transfer);
            });
        },
        [port]
    );

    const cancel: WalletContextValue['cancel'] = useCallback(
        () => send({ id: input?.id, type: IcarusCancel }),
        [send, input]
    );

    const close: WalletContextValue['close'] = useCallback(
        () => send({ id: input?.id, type: IcarusClose }),
        [send, input]
    );

    const createPasskey: WalletContextValue['createPasskey'] = useCallback(
        async (options = {}) => {
            const { input } = await send({ type: IcarusCreatePasskey, output: options });
            return input.passkey;
        },
        [send]
    );

    const getPasskey: WalletContextValue['getPasskey'] = useCallback(
        async (options = {}) => {
            const { input } = await send({ type: IcarusGetPasskey, output: options });
            return input.passkey;
        },
        [send]
    );

    const deriveAccounts: WalletContextValue['deriveAccounts'] = useCallback(
        async (passkey, derivedAccounts) => {
            const { input } = await send({ type: IcarusDeriveAccounts, output: { passkey, derivedAccounts } });
            return input.accounts;
        },
        [send]
    );

    const sign: WalletContextValue['sign'] = useCallback(
        async (account, bytes) => {
            const { input } = await send({ type: IcarusSign, output: { account, bytes } });
            return input.signature;
        },
        [send]
    );

    const setState: WalletContextValue['setState'] = useCallback(
        (state) => send({ type: IcarusState, output: state }),
        [send]
    );

    // Memoize the context value for performance, and freeze it so it can't be modified by components.
    const value = useMemo(
        () =>
            frozen({
                origin,
                input,
                output,
                passkeys,
                accounts,
                syncPasskey,
                applications,
                application,
                errors,
                send,
                cancel,
                close,
                createPasskey,
                getPasskey,
                deriveAccounts,
                sign,
                setState,
            }),
        [
            origin,
            input,
            output,
            passkeys,
            accounts,
            syncPasskey,
            applications,
            application,
            errors,
            send,
            cancel,
            close,
            createPasskey,
            getPasskey,
            deriveAccounts,
            sign,
            setState,
        ]
    );

    return <WalletContext.Provider value={value}>{children}</WalletContext.Provider>;
}
