import { createContext } from 'preact';

import type {
    Accounts,
    Application,
    Applications,
    DerivedAccount,
    Passkey,
    PasskeyAccount,
    Passkeys,
    SignatureBytes,
    URLString,
} from '@icarus/types';
import type {
    IcarusCreatePasskeyInputMessage,
    IcarusCreatePasskeyOutputMessage,
    IcarusDeriveAccountsInputMessage,
    IcarusDeriveAccountsOutputMessage,
    IcarusGetPasskeyInputMessage,
    IcarusGetPasskeyOutputMessage,
    IcarusSignInputMessage,
    IcarusSignOutputMessage,
    ErrorMessage as UIErrorMessage,
    InputMessage as UIInputMessage,
    OutputMessage as UIOutputMessage,
} from '@icarus/ui-messages';
import type { Frozen } from '@icarus/utils';
import type {
    ErrorMessage as WalletErrorMessage,
    InputMessage as WalletInputMessage,
    OutputMessage as WalletOutputMessage,
} from '@icarus/wallet-messages';

export type InputMessage = UIInputMessage | WalletInputMessage;
export type OutputMessage = UIOutputMessage | WalletOutputMessage;
export type ErrorMessage = UIErrorMessage | WalletErrorMessage;

export interface WalletContextValue {
    origin: URLString | null;
    input: InputMessage | null;
    output: OutputMessage | null;
    applications: Applications;
    application: Application | null;
    passkeys: Passkeys;
    accounts: Accounts;
    errors: ErrorMessage[];
    syncPasskey: boolean;
    send<O extends OutputMessage>(
        output: O,
        transfer?: ArrayBuffer[]
    ): Promise<
        O extends IcarusCreatePasskeyOutputMessage
            ? IcarusCreatePasskeyInputMessage
            : O extends IcarusGetPasskeyOutputMessage
            ? IcarusGetPasskeyInputMessage
            : O extends IcarusDeriveAccountsOutputMessage
            ? IcarusDeriveAccountsInputMessage
            : O extends IcarusSignOutputMessage
            ? IcarusSignInputMessage
            : undefined
    >;
    cancel(): void;
    close(): void;
    // FIXME: Fix these options.
    createPasskey(options?: { name?: string }): Promise<Passkey>;
    getPasskey(options?: Record<string, never>): Promise<Passkey>;
    deriveAccounts(passkey: Passkey, derivedAccounts: DerivedAccount[]): Promise<PasskeyAccount[]>;
    sign(account: PasskeyAccount, bytes: Uint8Array): Promise<SignatureBytes>;
    setState(state: { application?: Application; syncPasskey?: boolean }): void;
}

export const WalletContext = createContext<Frozen<WalletContextValue> | undefined>(undefined);
