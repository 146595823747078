import { Suspense } from 'preact/compat';
import { Route, type RouteProps } from 'wouter-preact';

import Loading from './Loading.js';

export default function AsyncRoute({ children, ...props }: RouteProps) {
    return (
        <Route {...props}>
            <Suspense fallback={<Loading />}>{children}</Suspense>
        </Route>
    );
}
