import { Buffer } from 'buffer';

import { render } from 'preact';
import { Workbox } from 'workbox-window';

import Routes from './routes/index.js';

import './globals.css';

// HACK: Remove this polyfill for @solana/web3.js.
globalThis.Buffer ??= Buffer;

globalThis.global ??= globalThis;

render(<Routes />, document.body);

// FIXME: Might need to do this after user activation inside the iframe.
// FIXME: Add event listeners and other things to this.
const workbox = new Workbox('/worker.js', { type: import.meta.env.PROD ? 'classic' : 'module' });
workbox.register();
