import { Toaster as Sonner } from 'sonner';

export default function Toaster() {
    return (
        <Sonner
            position="top-center"
            theme="dark"
            richColors
            closeButton
            visibleToasts={7}
            duration={5000}
            toastOptions={{ className: 'toast' }}
        />
    );
}
