import { LedgerTransport } from '@icarus/types';
import { DashboardName, getAppInfo, getDeviceName, quitApp } from './ledger.js';
import { connectTransport } from './transport.js';
export async function connect(transport) {
    const transportInstance = await connectTransport(transport);
    let name = '';
    let product = '';
    const transports = [transport];
    // Use duck typing because `device` may be a Ledger `Device`, Browser `HIDDevice`, or something else.
    if ('device' in transportInstance && typeof transportInstance.device === 'object') {
        const device = transportInstance.device;
        // Ledger `Device` type.
        if ('name' in device && typeof device.name === 'string') {
            name = device.name;
        }
        // Browser `HIDDevice` type.
        if ('productName' in device && typeof device.productName === 'string') {
            product = device.productName;
        }
    }
    const deviceModel = transportInstance.deviceModel;
    if (deviceModel) {
        if (!product) {
            product = deviceModel.productName;
        }
        // For devices that support Bluetooth, note this in the output.
        if (transport !== LedgerTransport.BLE && !deviceModel.usbOnly && !!deviceModel.bluetoothSpec) {
            transports.push(LedgerTransport.BLE);
        }
    }
    // If it's a Bluetooth device, the name will be typically be provided. If not, ask the user for it, but fail gracefully if they decline.
    if (!name) {
        try {
            // If the dashboard isn't open, quit the current app so we can get the device name.
            const appInfo = await getAppInfo(transport);
            if (!(appInfo.name in DashboardName)) {
                await quitApp(transport);
            }
            name = await getDeviceName(transport);
        }
        catch (error) {
            // Silently catch and use a default name.
        }
    }
    // Some Ledger device/product names contain nonbreaking spaces and/or a leading "Ledger" title.
    name = name.replace(/\u00A0/g, ' ').replace(/^Ledger\s*/, '');
    product = product.replace(/\u00A0/g, ' ').replace(/^Ledger\s*/, '');
    // Default to the product name or "Ledger" if we couldn't find a device name.
    if (!name) {
        if (product) {
            name = product;
        }
        else {
            name = 'Ledger';
        }
    }
    // Default to the device name or "Ledger" if we couldn't find a product name.
    if (!product) {
        product = name;
    }
    return { name, product, transports };
}
