export function frozen(source) {
    if (!source || typeof source !== 'object')
        return source;
    if (Array.isArray(source))
        return Object.freeze(source.map(frozen));
    if (source instanceof Uint8Array) {
        const buffer = source.buffer.slice(0);
        const copy = new Uint8Array(buffer, 0);
        Object.defineProperty(copy, 'buffer', {
            get() {
                return buffer.slice(0);
            },
        });
        return copy;
    }
    const copy = {};
    for (const [property, value] of Object.entries(source)) {
        copy[property] = frozen(value);
    }
    return Object.freeze(copy);
}
